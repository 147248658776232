import dynamic from 'next/dynamic';
import { ReactNode, useEffect, useState } from 'react';
import { COLORS } from 'styles/constants';
import { LiquidButton } from '../../../../LiquidButton';
import { ButtonFlexContainer, Container, StyledSubmitButton } from '../../../styles';
import type { ISubmitButton } from '../../types';
const FriendlyCaptcha = dynamic(
  () => import('../../../Common/components/FriendlyCaptcha').then((mod) => mod.FriendlyCaptcha),
  {
    ssr: false,
  },
);

export const SubmitButton = ({
  field,
  formikProps,
  onCaptchaSuccess,
}: {
  field: ISubmitButton;
  formikProps: any;
  onCaptchaSuccess?: (solution: string) => void;
}): ReactNode => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <>
      {!!onCaptchaSuccess && isClient && (
        <>
          <FriendlyCaptcha
            onSuccess={(solution) => {
              onCaptchaSuccess(solution);
            }}
          />
        </>
      )}
      <Container>
        <ButtonFlexContainer>
          <StyledSubmitButton
            type="submit"
            value={field.buttonText}
            disabled={formikProps.isSubmitting}
            data-test={`submit-${field.buttonText}`}
          >
            <LiquidButton
              text={field.buttonText}
              width={240}
              height={52}
              color={COLORS.primary}
              textColor={COLORS.white}
            />
          </StyledSubmitButton>
        </ButtonFlexContainer>
      </Container>
    </>
  );
};
