import { VideoProps } from '@graphcms/rich-text-types';
import escapeHTML from 'escape-html';
import Head from 'next/head';
import React from 'react';

interface SchemaVideoProps {
  name?: string;
  description?: string;
  thumbnailUrls?: string[];
  embedUrl?: string;
  uploadDate?: string;
  duration?: string;
  interactionCount?: number;
}

type VideoPropsCombined = SchemaVideoProps & Partial<VideoProps>;

export function Video({
  src,
  width,
  height,
  title,
  name,
  description,
  thumbnailUrls,
  embedUrl,
  uploadDate,
  duration,
  interactionCount,
  ...rest
}: VideoPropsCombined) {
  const schemaOrgData = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: name,
    description: description,
    contentUrl: src,
    ...(thumbnailUrls && { thumbnailUrl: thumbnailUrls }),
    ...(embedUrl && { embedUrl: embedUrl }),
    ...(uploadDate && { uploadDate: uploadDate }),
    ...(duration && { duration: duration }),
    ...(interactionCount && {
      interactionStatistic: {
        '@type': 'InteractionCounter',
        interactionType: { '@type': 'WatchAction' },
        userInteractionCount: interactionCount,
      },
    }),
  };

  return (
    <>
      <Head>
        <script type="application/ld+json">{JSON.stringify(schemaOrgData)}</script>
      </Head>
      <div>
        <iframe
          src={escapeHTML(src)}
          width={width || '100%'}
          height={height || '100%'}
          title={title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="w-full"
          {...rest}
        />
      </div>
    </>
  );
}
