import React from 'react';
import { MetaBallImage } from './components/MetaBallImage';

export interface IMetaBallContainerProps {
  children: React.ReactNode;
  topLeftImage?: string;
  topRightImage?: string;
  bottomLeftImage?: string;
  bottomRightImage?: string;
}

export const MetaBallContainer = ({
  children,
  topRightImage,
  bottomRightImage,
  bottomLeftImage,
  topLeftImage,
}: IMetaBallContainerProps) => (
  <section
    className="print:hidden relative md:my-20 sm:my-0 py-16 md:py-0 min-w-full overflow-hidden"
    id="metaball-container"
  >
    <MetaBallImage src={topRightImage} type="topRight" />
    <MetaBallImage src={bottomRightImage} type="bottomRight" />
    <MetaBallImage src={bottomLeftImage} type="bottomLeft" />
    <MetaBallImage src={topLeftImage} type="topLeft" />
    {children}
  </section>
);
