/* eslint-disable no-param-reassign */
import { Success } from '@icons/Success';
import axios from 'axios';
import { Grid, GridItem, GridRow } from 'components/Grid';
import { MetaBallContainer } from 'components/MetaBallContainer';
import { Formik } from 'formik';
import { useRef, useState } from 'react';
import { FormTypes } from 'server/repository/hygraph/generated/graphqlTypes';
import { NewsletterTracking } from 'service/tracking/newsletter';
import * as yup from 'yup';
import { ErrorMessage, SuccessDescription, SuccessMessage, SuccessWrapper } from '../styles';
import { FormFields } from './components/FormFields';
import { initialValueMap } from './components/FormFields/componentMap';
import { validationSchemaMap } from './validation';

const getRequestUrl = (formType: string) => {
  switch (formType) {
    case 'newsletter':
      return '/next-api/newsletter/user';
    case 'promotion':
      return '/next-api/promotion/user';
    default:
      return '/next-api/contact';
  }
};

export type FormProps = {
  formData: any;
  trackingEvents?: { trackSubmitTrueEvent: (subscriptionType: string) => void };
  successComponents?: any;
  disableScrollOnSubmit?: boolean;
  formSubmitCallback?: () => void;
  slug?: string | string[];
  withCapture?: boolean;
  formType: 'newsletter' | 'promotion' | 'contact';
};

// Form container component.
// Sets up a form with Formik, validations, success and error states, and submission handling.
// Pass in a form built in the CMS.

export const Form = ({
  formData,
  trackingEvents,
  successComponents,
  disableScrollOnSubmit = false,
  formSubmitCallback = () => {},
  slug = '',
  withCapture = false,
  formType,
}: FormProps) => {
  const [formSuccess, setFormSuccess] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>(formData?.formErrors?.[0]?.message);
  const [formFailed, setFormFailed] = useState<boolean>(false);
  const [count, setCount] = useState<number>(1);
  const [captchaSolution, setCaptchaSolution] = useState<string>();
  const successMessageRef = useRef<HTMLDivElement>(null);

  const requestURL = getRequestUrl(formType);

  const trackOnSubmitClick = (formType: FormTypes, subscriptionType?: string) => {
    if (formType === 'newsletter' && !trackingEvents?.trackSubmitTrueEvent) {
      NewsletterTracking.trackSubmitTrueEvent(subscriptionType || '');
      return;
    }

    trackingEvents?.trackSubmitTrueEvent(subscriptionType || '');
  };

  if (!formData?.formFields) return null;

  // Additional field to prevent spam (Honeypot solution)
  const addressFields = formData.formFields.find(
    (item: { fieldName: string }) => item.fieldName === 'address_line_0',
  );
  if (!addressFields) {
    formData.formFields.push({
      date: false,
      fieldName: 'address_line_0',
      hidden: true,
      id: 'address_line_0',
      initialValue: '',
      label: null,
      placeholderText: null,
      required: false,
      requiredErrorMessage: null,
      title: 'Address Line 0',
      __typename: 'FormTextInput',
    });
  }

  if (formSuccess) {
    if (!disableScrollOnSubmit && successMessageRef.current) {
      successMessageRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      window.scrollTo(0, 0);
    }
  }

  const handleError = (e: any) => {
    setFormSuccess(false);
    setFormFailed(true);
    let errorMessage;
    if (e?.code) {
      formData?.formErrors?.forEach((error: { code?: number; message?: string }) => {
        if (error.code === e.code) {
          errorMessage = error.message;
        }
      });
    }
    setFormError(errorMessage || 'Some error occurred, please try again.');
  };

  const handleSubmit = (values: any, actions: any) => {
    setFormSuccess(false);
    setFormFailed(false);

    values.market = formData?.market;
    values.slug = slug;
    if (formData?.formType !== 'promotion') {
      if (!values.from) {
        values.from = 'do_not_reply@oetker.com';
      }
      if (!values.subject) {
        values.subject = 'No subject';
      }
    }
    if (withCapture) {
      if (!captchaSolution) {
        setFormSuccess(false);
        setFormFailed(true);
        setFormError('Some error occurred, please try again.');
        return;
      }
      values.solution = captchaSolution;
    }

    const { ...data } = values;

    axios
      .post(requestURL, data)
      .then(() => {
        setFormSuccess(true);
        formSubmitCallback();
        trackOnSubmitClick(formData?.formType, values?.SubscriptionType?.join(', '));
      })
      .catch((e) => {
        handleError(e);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const initialValues = initialValueMap(formData?.formFields);
  const validationSchema = validationSchemaMap(formData?.formFields);
  return (
    <>
      {!formSuccess && (
        <MetaBallContainer
          data-test={`${formData?.id}-metaball-container`}
          topRightImage={!formSuccess && formData?.upperRightCorner?.url}
          bottomLeftImage={!formSuccess && formData?.lowerLeftCorner?.url}
        >
          <Grid>
            <div className="w-[80%] mx-auto md:w-full">
              <GridRow>
                <GridItem>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object().shape(validationSchema)}
                    onSubmit={handleSubmit}
                  >
                    {(formikProps) => (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          formikProps.handleSubmit(e);
                          setCount(1 + 1);
                        }}
                        data-test={formData?.formType}
                        className={`
                        flex
                        flex-col
                        items-center
                        ${formData?.upperRightCorner?.url && 'pt-10 md:pt-0'}
                        ${formData?.lowerLeftCorner?.url && 'pb-20 md:pb-0'}
                      `}
                      >
                        {formData?.title && <p>{formData?.title}</p>}
                        <FormFields
                          fields={formData?.formFields}
                          withCapture={withCapture}
                          setCaptchaSolution={setCaptchaSolution}
                          formikProps={formikProps}
                          count={count}
                        />
                      </form>
                    )}
                  </Formik>
                  {formFailed && !formSuccess ? <ErrorMessage>{formError}</ErrorMessage> : ''}
                </GridItem>
              </GridRow>
            </div>
          </Grid>
        </MetaBallContainer>
      )}
      {formSuccess && (
        <>
          <Grid>
            <GridRow>
              <GridItem>
                <SuccessWrapper
                  ref={successMessageRef}
                  $scroll={formData?.formType !== 'promotion'}
                >
                  <Success />
                  <SuccessMessage tag="div" type="sm">
                    {formData?.formSuccess?.message}
                  </SuccessMessage>
                  <SuccessDescription tag="div" type="md">
                    {formData?.formSuccess?.description}
                  </SuccessDescription>
                </SuccessWrapper>
              </GridItem>
            </GridRow>
          </Grid>
          {successComponents ?? null}
        </>
      )}
    </>
  );
};
