export const SALUTATION_VALUES = [
  { value: 'Mr', dictionaryKey: 'Mr' },
  { value: 'Ms', dictionaryKey: 'Ms' },
  { value: 'Diverse', dictionaryKey: 'Diverse' },
];

export const GENDER_VALUES = [
  { value: 'male', dictionaryKey: 'male' },
  { value: 'female', dictionaryKey: 'female' },
  { value: 'diverse', dictionaryKey: 'diverse' },
];

export const PROMOTION_SOURCE_TOOL = 'HygraphWebsite';

export const PROMOTION_SOURCE_TYPE = 'Offsite';

export const LEGAL_SUBSCRIPTION_VALUE = '3rdPartyOptin';

export const COUNTRIES = [
  { countryCode: 'DE', country: 'Deutschland' },
  { countryCode: 'GB', country: 'United Kingdom' },
  { countryCode: 'CH', country: 'Schweiz' },
  { countryCode: 'FR', country: 'France' },
  { countryCode: 'IT', country: 'Italia' },
  { countryCode: 'US', country: 'United States' },
  { countryCode: 'AD', country: 'Andorra' },
  { countryCode: 'AO', country: 'Angola' },
  { countryCode: 'AI', country: 'Anguilla' },
  { countryCode: 'AQ', country: 'Antarctica' },
  { countryCode: 'AG', country: 'Antigua and Barbuda' },
  { countryCode: 'AR', country: 'Argentina' },
  { countryCode: 'AL', country: 'Shqipëria' },
  { countryCode: 'AS', country: 'American Samoa' },
  { countryCode: 'AM', country: 'Հայաստան' },
  { countryCode: 'AW', country: 'Aruba' },
  { countryCode: 'AU', country: 'Australia' },
  { countryCode: 'AT', country: 'Österreich' },
  { countryCode: 'AZ', country: 'Azərbaycan' },
  { countryCode: 'BS', country: 'Bahamas' },
  { countryCode: 'BH', country: 'البحرين' },
  { countryCode: 'BD', country: 'বাংলাদেশ' },
  { countryCode: 'BB', country: 'Barbados' },
  { countryCode: 'BY', country: 'Беларусь' },
  { countryCode: 'BE', country: 'België' },
  { countryCode: 'BZ', country: 'Belize' },
  { countryCode: 'BJ', country: 'Bénin' },
  { countryCode: 'BM', country: 'Bermuda' },
  { countryCode: 'BT', country: 'འབྲུག་ཡུལ' },
  { countryCode: 'BO', country: 'Bolivia' },
  { countryCode: 'BA', country: 'Bosna i Hercegovina' },
  { countryCode: 'BW', country: 'Botswana' },
  { countryCode: 'BV', country: 'Bouvet Island' },
  { countryCode: 'BR', country: 'Brasil' },
  { countryCode: 'IO', country: 'British Indian Ocean Territory' },
  { countryCode: 'BN', country: 'Brunei Darussalam' },
  { countryCode: 'BG', country: 'България' },
  { countryCode: 'BF', country: 'Burkina Faso' },
  { countryCode: 'BI', country: 'Burundi' },
  { countryCode: 'CV', country: 'Cabo Verde' },
  { countryCode: 'KH', country: 'កម្ពុជា' },
  { countryCode: 'CM', country: 'Cameroon' },
  { countryCode: 'CA', country: 'Canada' },
  { countryCode: 'KY', country: 'Cayman Islands' },
  { countryCode: 'CF', country: 'République centrafricaine' },
  { countryCode: 'TD', country: 'Tchad' },
  { countryCode: 'CL', country: 'Chile' },
  { countryCode: 'CN', country: '中国' },
  { countryCode: 'CX', country: 'Christmas Island' },
  { countryCode: 'CC', country: 'Cocos (Keeling) Islands' },
  { countryCode: 'CO', country: 'Colombia' },
  { countryCode: 'KM', country: 'جزر القمر' },
  { countryCode: 'CG', country: 'République du Congo' },
  { countryCode: 'CD', country: 'République démocratique du Congo' },
  { countryCode: 'CK', country: 'Cook Islands' },
  { countryCode: 'CR', country: 'Costa Rica' },
  { countryCode: 'HR', country: 'Hrvatska' },
  { countryCode: 'CU', country: 'Cuba' },
  { countryCode: 'CY', country: 'Κύπρος' },
  { countryCode: 'CZ', country: 'Česká republika' },
  { countryCode: 'DK', country: 'Danmark' },
  { countryCode: 'DJ', country: 'Djibouti' },
  { countryCode: 'DM', country: 'Dominica' },
  { countryCode: 'DO', country: 'República Dominicana' },
  { countryCode: 'EC', country: 'Ecuador' },
  { countryCode: 'EG', country: 'مصر' },
  { countryCode: 'SV', country: 'El Salvador' },
  { countryCode: 'GQ', country: 'Guinea Ecuatorial' },
  { countryCode: 'ER', country: 'ኤርትራ' },
  { countryCode: 'EE', country: 'Eesti' },
  { countryCode: 'SZ', country: 'Eswatini' },
  { countryCode: 'ET', country: 'ኢትዮጵያ' },
  { countryCode: 'FK', country: 'Falkland Islands' },
  { countryCode: 'FO', country: 'Føroyar' },
  { countryCode: 'FJ', country: 'Fiji' },
  { countryCode: 'FI', country: 'Suomi' },
  { countryCode: 'GF', country: 'Guyane française' },
  { countryCode: 'PF', country: 'Polynésie française' },
  { countryCode: 'TF', country: 'Terres australes et antarctiques françaises' },
  { countryCode: 'GA', country: 'Gabon' },
  { countryCode: 'GM', country: 'Gambia' },
  { countryCode: 'GE', country: 'საქართველო' },
  { countryCode: 'GH', country: 'Ghana' },
  { countryCode: 'GI', country: 'Gibraltar' },
  { countryCode: 'GR', country: 'Ελλάδα' },
  { countryCode: 'GL', country: 'Kalaallit Nunaat' },
  { countryCode: 'GD', country: 'Grenada' },
  { countryCode: 'GP', country: 'Guadeloupe' },
  { countryCode: 'GU', country: 'Guam' },
  { countryCode: 'GT', country: 'Guatemala' },
  { countryCode: 'GG', country: 'Guernsey' },
  { countryCode: 'GN', country: 'Guinée' },
  { countryCode: 'GW', country: 'Guiné-Bissau' },
  { countryCode: 'GY', country: 'Guyana' },
  { countryCode: 'HT', country: 'Haïti' },
  { countryCode: 'HM', country: 'Heard Island and McDonald Islands' },
  { countryCode: 'VA', country: 'Vaticano' },
  { countryCode: 'HN', country: 'Honduras' },
  { countryCode: 'HK', country: '香港' },
  { countryCode: 'HU', country: 'Magyarország' },
  { countryCode: 'IS', country: 'Ísland' },
  { countryCode: 'IN', country: 'भारत' },
  { countryCode: 'ID', country: 'Indonesia' },
  { countryCode: 'IR', country: 'ایران' },
  { countryCode: 'IQ', country: 'العراق' },
  { countryCode: 'IE', country: 'Éire' },
  { countryCode: 'IM', country: 'Isle of Man' },
  { countryCode: 'IL', country: 'ישראל' },
  { countryCode: 'JM', country: 'Jamaica' },
  { countryCode: 'JP', country: '日本' },
  { countryCode: 'JE', country: 'Jersey' },
  { countryCode: 'JO', country: 'الأردن' },
  { countryCode: 'KZ', country: 'Қазақстан' },
  { countryCode: 'KE', country: 'Kenya' },
  { countryCode: 'KI', country: 'Kiribati' },
  { countryCode: 'KP', country: '조선민주주의인민공화국' },
  { countryCode: 'KR', country: '대한민국' },
  { countryCode: 'KW', country: 'الكويت' },
  { countryCode: 'KG', country: 'Кыргызстан' },
  { countryCode: 'LA', country: 'ປະເທດລາວ' },
  { countryCode: 'LV', country: 'Latvija' },
  { countryCode: 'LB', country: 'لبنان' },
  { countryCode: 'LS', country: 'Lesotho' },
  { countryCode: 'LR', country: 'Liberia' },
  { countryCode: 'LY', country: 'ليبيا' },
  { countryCode: 'LI', country: 'Liechtenstein' },
  { countryCode: 'LT', country: 'Lietuva' },
  { countryCode: 'LU', country: 'Luxembourg' },
  { countryCode: 'MO', country: '澳門' },
  { countryCode: 'MG', country: 'Madagasikara' },
  { countryCode: 'MW', country: 'Malawi' },
  { countryCode: 'MY', country: 'Malaysia' },
  { countryCode: 'MV', country: 'Maldives' },
  { countryCode: 'ML', country: 'Mali' },
  { countryCode: 'MT', country: 'Malta' },
  { countryCode: 'MH', country: 'Marshall Islands' },
  { countryCode: 'MQ', country: 'Martinique' },
  { countryCode: 'MR', country: 'موريتانيا' },
  { countryCode: 'MU', country: 'Maurice' },
  { countryCode: 'YT', country: 'Mayotte' },
  { countryCode: 'MX', country: 'México' },
  { countryCode: 'FM', country: 'Micronesia' },
  { countryCode: 'MD', country: 'Moldova' },
  { countryCode: 'MC', country: 'Monaco' },
  { countryCode: 'MN', country: 'Монгол Улс' },
  { countryCode: 'ME', country: 'Crna Gora' },
  { countryCode: 'MS', country: 'Montserrat' },
  { countryCode: 'MA', country: 'المغرب' },
  { countryCode: 'MZ', country: 'Moçambique' },
  { countryCode: 'MM', country: 'မြန်မာ' },
  { countryCode: 'NA', country: 'Namibia' },
  { countryCode: 'NR', country: 'Nauru' },
  { countryCode: 'NP', country: 'नेपाल' },
  { countryCode: 'NL', country: 'Nederland' },
  { countryCode: 'NC', country: 'Nouvelle-Calédonie' },
  { countryCode: 'NZ', country: 'New Zealand' },
  { countryCode: 'NI', country: 'Nicaragua' },
  { countryCode: 'NE', country: 'Niger' },
  { countryCode: 'NG', country: 'Nigeria' },
  { countryCode: 'NU', country: 'Niue' },
  { countryCode: 'NF', country: 'Norfolk Island' },
  { countryCode: 'MK', country: 'Северна Македонија' },
  { countryCode: 'MP', country: 'Northern Mariana Islands' },
  { countryCode: 'NO', country: 'Norge' },
  { countryCode: 'OM', country: 'عمان' },
  { countryCode: 'PK', country: 'پاکستان' },
  { countryCode: 'PW', country: 'Palau' },
  { countryCode: 'PS', country: 'فلسطين' },
  { countryCode: 'PA', country: 'Panamá' },
  { countryCode: 'PG', country: 'Papua New Guinea' },
  { countryCode: 'PY', country: 'Paraguay' },
  { countryCode: 'PE', country: 'Perú' },
  { countryCode: 'PH', country: 'Pilipinas' },
  { countryCode: 'PN', country: 'Pitcairn Islands' },
  { countryCode: 'PL', country: 'Polska' },
  { countryCode: 'PT', country: 'Portugal' },
  { countryCode: 'PR', country: 'Puerto Rico' },
  { countryCode: 'QA', country: 'قطر' },
  { countryCode: 'RE', country: 'La Réunion' },
  { countryCode: 'RO', country: 'România' },
  { countryCode: 'RU', country: 'Россия' },
  { countryCode: 'RW', country: 'Rwanda' },
  { countryCode: 'BL', country: 'Saint-Barthélemy' },
  { countryCode: 'SH', country: 'Saint Helena' },
  { countryCode: 'KN', country: 'Saint Kitts and Nevis' },
  { countryCode: 'LC', country: 'Saint Lucia' },
  { countryCode: 'MF', country: 'Saint-Martin' },
  { countryCode: 'PM', country: 'Saint-Pierre-et-Miquelon' },
  { countryCode: 'VC', country: 'Saint Vincent and the Grenadines' },
  { countryCode: 'WS', country: 'Samoa' },
  { countryCode: 'SM', country: 'San Marino' },
  { countryCode: 'ST', country: 'São Tomé e Príncipe' },
  { countryCode: 'SA', country: 'السعودية' },
  { countryCode: 'SN', country: 'Sénégal' },
  { countryCode: 'RS', country: 'Србија' },
  { countryCode: 'SC', country: 'Seychelles' },
  { countryCode: 'SL', country: 'Sierra Leone' },
  { countryCode: 'SG', country: 'Singapore' },
  { countryCode: 'SX', country: 'Sint Maarten' },
  { countryCode: 'SK', country: 'Slovensko' },
  { countryCode: 'SI', country: 'Slovenija' },
  { countryCode: 'SB', country: 'Solomon Islands' },
  { countryCode: 'SO', country: 'Somalia' },
  { countryCode: 'ZA', country: 'South Africa' },
  { countryCode: 'GS', country: 'South Georgia and the South Sandwich Islands' },
  { countryCode: 'SS', country: 'South Sudan' },
  { countryCode: 'ES', country: 'España' },
  { countryCode: 'LK', country: 'ශ්‍රී ලංකාව' },
  { countryCode: 'SD', country: 'السودان' },
  { countryCode: 'SR', country: 'Suriname' },
  { countryCode: 'SJ', country: 'Svalbard and Jan Mayen' },
  { countryCode: 'SE', country: 'Sverige' },
  { countryCode: 'SY', country: 'سوريا' },
  { countryCode: 'TW', country: '台灣' },
  { countryCode: 'TJ', country: 'Тоҷикистон' },
  { countryCode: 'TZ', country: 'Tanzania' },
  { countryCode: 'TH', country: 'ประเทศไทย' },
  { countryCode: 'TL', country: 'Timor-Leste' },
  { countryCode: 'TG', country: 'Togo' },
  { countryCode: 'TK', country: 'Tokelau' },
  { countryCode: 'TO', country: 'Tonga' },
  { countryCode: 'TT', country: 'Trinidad and Tobago' },
  { countryCode: 'TN', country: 'تونس' },
  { countryCode: 'TR', country: 'Türkiye' },
  { countryCode: 'TM', country: 'Türkmenistan' },
  { countryCode: 'TC', country: 'Turks and Caicos Islands' },
  { countryCode: 'TV', country: 'Tuvalu' },
  { countryCode: 'UG', country: 'Uganda' },
  { countryCode: 'UA', country: 'Україна' },
  { countryCode: 'AE', country: 'الإمارات العربية المتحدة' },
  { countryCode: 'UM', country: 'United States Minor Outlying Islands' },
  { countryCode: 'UY', country: 'Uruguay' },
  { countryCode: 'UZ', country: 'Oʻzbekiston' },
  { countryCode: 'VU', country: 'Vanuatu' },
  { countryCode: 'VE', country: 'Venezuela' },
  { countryCode: 'VN', country: 'Việt Nam' },
  { countryCode: 'VG', country: 'British Virgin Islands' },
  { countryCode: 'VI', country: 'U.S. Virgin Islands' },
  { countryCode: 'WF', country: 'Wallis et Futuna' },
  { countryCode: 'EH', country: 'الصحراء الغربية' },
  { countryCode: 'YE', country: 'اليمن' },
  { countryCode: 'ZM', country: 'Zambia' },
  { countryCode: 'ZW', country: 'Zimbabwe' },
  { countryCode: 'AF', country: 'افغانستان' },
  { countryCode: 'DZ', country: 'الجزائر' },
];
