import { LongText } from 'components/LongText';
import { FormikProps } from 'formik';
import { ReactNode } from 'react';
import type { FormFieldAttributes } from '../../types';
import { componentMap } from './componentMap';

type FormFieldsProps = {
  fields: FormFieldAttributes[];
  withCapture: boolean;
  setCaptchaSolution: (solution: string) => void;
  formikProps: FormikProps<any>;
  count: number;
};

export const FormFields = ({
  formikProps,
  withCapture,
  fields,
  setCaptchaSolution,
  count,
}: FormFieldsProps) => {
  return fields?.map((field: FormFieldAttributes, index: number): ReactNode | null => {
    // @ts-ignore
    const FieldComponent = componentMap[field.__typename];
    if (FieldComponent) {
      return (
        <FieldComponent
          key={field.id || index}
          field={field}
          formikProps={formikProps}
          count={count}
          onCaptchaSuccess={withCapture && setCaptchaSolution}
        />
      );
    }
    if (field.__typename === 'LongText') {
      return <LongText key={field.id || index} longText={field.longText} />;
    }
    return <div key={field.id} />;
  });
};
